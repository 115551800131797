<template>
    <div class="page dashboard">

        <!-- Welcome message -->
        <div class="page-area welcome">
            <h1 v-if="welcome_message">{{welcome_message}}.</h1>
            <h1 v-else>Hey there.</h1>
            <h2 v-if="organization == true">Please choose a product from your organization to get started</h2>
            <h2 v-if="organization == false">Here's what's happening with your customer feedback loop recently</h2>
            <div class="background"></div>
        </div>

        <!-- Load messages -->
        <message/>

        <!-- Getting started -->
        <onboarder v-if="organization == false"/>

        <!-- Organization Home -->
        <organization-home v-if="organization == true"/>

        <!-- Main area starts here -->
        <div class="page-area" v-if="organization == false">
            <div class="row sections-grid">

                <!-- Section -->
                <div class="col-xs-3">
                    <div class="stats-feature box" v-if="stats_30d">
                        <div class="content">
                            <div class="key">Tracked user</div>
                            <div class="count">
                                <div class="current">{{stats_30d.user ? stats_30d.user.total_period : 0 | format_stats}}</div>
                                <div class="change trend">
                                    <span class="value">of {{stats_30d.general && stats_30d.general.total_user ? stats_30d.general.total_user : 0 | format_stats}}</span>
                                </div>
                            </div>
                            <div class="txt">last 30 days</div>
                        </div>
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>People</title><path d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                        </div>
                    </div>
                    <div class="stats-feature box" v-else>
                        <loader type="dark" height="75" size="large"/>
                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-3">
                    <div class="stats-feature box" v-if="stats_30d">
                        <div class="content">
                            <div class="key">Emails</div>
                            <div class="count">
                                <div class="current">{{stats_30d.email ? stats_30d.email.total_period : 0 | format_stats}}</div>
                                <div class="change trend" :class="((stats_30d.email && stats_30d.email.total_trend > 0) ? 'up' : ((stats_30d.email.total_trend < 0) ? 'down' : ''))">
                                    <span class="icon">
                                        <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                        <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                        <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                    </span>
                                    <span class="value">{{stats_30d.email ? stats_30d.email.total_trend : 0}}%</span>
                                </div>
                            </div>
                            <div class="txt">last 30 days</div>
                        </div>
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Send</title><path d="M470.3 271.15L43.16 447.31a7.83 7.83 0 01-11.16-7V327a8 8 0 016.51-7.86l247.62-47c17.36-3.29 17.36-28.15 0-31.44l-247.63-47a8 8 0 01-6.5-7.85V72.59c0-5.74 5.88-10.26 11.16-8L470.3 241.76a16 16 0 010 29.39z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                        </div>
                    </div>
                    <div class="stats-feature box" v-else>
                        <loader type="dark" height="75" size="large"/>
                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-3" v-if="product.product_plan !== 'free'">
                    <div class="stats-feature box" v-if="stats_30d">
                        <div class="content">
                            <div class="key">CSAT</div>
                            <div class="count">
                                <div class="current">{{stats_30d.scores ? stats_30d.scores.satisfaction_period : 0}}%</div>
                                <div class="change trend" :class="((stats_30d.scores && stats_30d.scores.satisfaction_trend > 0) ? 'up' : ((stats_30d.scores.satisfaction_trend < 0) ? 'down' : ''))">
                                    <span class="icon">
                                        <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                        <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                        <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                    </span>
                                    <span class="value">{{stats_30d.scores ? stats_30d.scores.satisfaction_trend : 0}}%</span>
                                </div>
                            </div>
                            <div class="txt">last 30 days</div>
                        </div>
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="ionicon"><title>Heart</title><path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path></svg>
                        </div>
                    </div>
                    <div class="stats-feature box" v-else>
                        <loader type="dark" height="75" size="large"/>
                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-3" v-if="product.product_plan !== 'free'">
                    <div class="stats-feature box" v-if="stats_30d">
                        <div class="content">
                            <div class="key">NPS®</div>
                            <div class="count">
                                <div class="current">{{stats_30d.scores ? stats_30d.scores.promoter_period : 0}}</div>
                                <div class="change trend" :class="((stats_30d.scores && stats_30d.scores.promoter_trend > 0) ? 'up' : ((stats_30d.scores.promoter_trend < 0) ? 'down' : ''))">
                                    <span class="icon">
                                        <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                        <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                        <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                    </span>
                                    <span class="value">{{stats_30d.scores ? stats_30d.scores.promoter_trend : 0}}%</span>
                                </div>
                            </div>
                            <div class="txt">last 30 days</div>
                        </div>
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="ionicon"><title>Chatbox</title><path d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path></svg>
                        </div>
                    </div>
                    <div class="stats-feature box" v-else>
                        <loader type="dark" height="75" size="large"/>
                    </div>
                </div>

                <div class="col-xs-6" v-if="product.product_plan == 'free'">
                    <div class="stats-feature box cyan">
                        <div class="content">
                            <div class="key">Current Plan</div>
                            <div class="count">
                                <div class="current">Free</div>
                            </div>
                            <div class="txt">Limited stats and features</div>
                        </div>
                        <router-link to="/settings/plan" class="action">
                            Upgrade
                        </router-link>
                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-12">
                    <div class="box stats-box analytics">
                        
                        <div class="top-section">
                            <div class="title">Overview</div>
                            <div class="range" v-if="$store.getters.product_plan_can('analytics_pageviews') == true">
                                <span :class="{'active': stats_range == 7}" v-on:click="product_stats_range(7)">7 days</span>
                                <span :class="{'active': stats_range == 30}" v-on:click="product_stats_range(30)">30 days</span>
                                <span :class="{'active': stats_range == 180}" v-on:click="product_stats_range(180)">6 month</span>
                                <span :class="{'active': stats_range == 365}" v-on:click="product_stats_range(365)">1 year</span>
                            </div>
                        </div>

                        <div class="stats-section" :class="{'upgrade-blur': $store.getters.product_plan_can('analytics_pageviews') !== true}">
                            <div class="item" :class="{active: !pageview_type}" v-on:click="product_pageviews_type(null)">
                                <div class="title">Total</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.pageviews ? stats.pageviews.total_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.pageviews && stats.pageviews.total_trend > 0) ? 'up' : ((stats.pageviews.total_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.pageviews ? stats.pageviews.total_trend : 0}}%</span>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="30" size="small"/>
                                </div>
                            </div>
                            <div class="item" :class="{active: pageview_type === 'pageview'}" v-on:click="product_pageviews_type('pageview')">
                                <div class="title">Pageviews</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.pageviews ? stats.pageviews.board_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.pageviews && stats.pageviews.board_trend > 0) ? 'up' : ((stats.pageviews.board_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.pageviews ? stats.pageviews.board_trend : 0}}%</span>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="30" size="small"/>
                                </div>
                            </div>
                            <div class="item" :class="{active: pageview_type === 'popup'}" v-on:click="product_pageviews_type('popup')">
                                <div class="title">Popups</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.pageviews ? stats.pageviews.popup_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.pageviews && stats.pageviews.popup_trend > 0) ? 'up' : ((stats.pageviews.popup_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.pageviews ? stats.pageviews.popup_trend : 0}}%</span>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="30" size="small"/>
                                </div>
                            </div>
                            <div class="item" :class="{active: pageview_type === 'announcement'}" v-on:click="product_pageviews_type('announcement')">
                                <div class="title">Announcements</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.pageviews ? stats.pageviews.announcement_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.pageviews && stats.pageviews.announcement_trend > 0) ? 'up' : ((stats.pageviews.announcement_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.pageviews ? stats.pageviews.announcement_trend : 0}}%</span>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="30" size="small"/>
                                </div>
                            </div>
                        </div>

                        <div class="pageviews-section" :class="{'upgrade-blur': $store.getters.product_plan_can('analytics_pageviews') !== true}">
                            <div class="pageviews-chart">
                                <div class="outer" :class="{loading: load_pageviews}">
                                    <div class="overlay-right"></div>
                                    <canvas id="analytics-pageviews" height="250"></canvas>
                                </div>
                                <div v-if="load_pageviews" class="chart-loading">
                                    <img class="loading" src="@/assets/media/svg/loading.svg">
                                </div>
                            </div>
                        </div>

                        <div class="chart-section" :class="{'upgrade-blur': product.product_plan == 'free'}">
                            <div class="item">
                                <div class="title">Posts</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.post_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.post_trend > 0) ? 'up' : ((stats.events.post_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.post_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-post" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">Comments</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.comment_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.comment_trend > 0) ? 'up' : ((stats.events.comment_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.comment_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-comment" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">Votes</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.vote_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.vote_trend > 0) ? 'up' : ((stats.events.vote_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.vote_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-vote" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">Subscriber</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.subscribe_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.subscribe_trend > 0) ? 'up' : ((stats.events.subscribe_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.subscribe_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-subscriber" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">NPS® Surveys</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.promoter_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.promoter_trend > 0) ? 'up' : ((stats.events.promoter_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.promoter_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-promoter" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">CSAT Surveys</div>
                                <div class="value" v-if="stats">
                                    <div class="current">{{stats.events ? stats.events.satisfaction_period : 0 | format_stats}}</div>
                                    <div class="change trend" :class="((stats.events && stats.events.satisfaction_trend > 0) ? 'up' : ((stats.events.satisfaction_trend < 0) ? 'down' : ''))">
                                        <span class="icon">
                                            <ion-icon class="plus" name="arrow-round-up"></ion-icon>
                                            <ion-icon class="none" name="arrow-round-forward"></ion-icon>
                                            <ion-icon class="minus" name="arrow-round-down"></ion-icon>
                                        </span>
                                        <span class="value">{{stats.events ? stats.events.satisfaction_trend : 0}}%</span>
                                    </div>
                                    <div class="chart">
                                        <canvas id="analytics-satisfaction" height="40"></canvas>
                                    </div>
                                </div>
                                <div class="value" v-else>
                                    <loader type="dark" height="40" size="small"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-8">
                    <div class="box stats-box">
                        
                        <h2>Recent Activites</h2>

                        <!-- result area -->
                        <div v-if="product_feed">
                            <div v-if="product_feed.length > 0">

                                <div class="event-feed">

                                    <div class="feed-item" v-for="(feed_item, key) in product_feed.slice(0, 8)" v-bind:key="key">
                                        <router-link :to="'/user/' + feed_item.user.user_id" class="image" :style="'background-image: url(' + feed_item.user.data_img + ')'"></router-link>
                                        <div class="feed-data">
                                            <router-link :to="'/user/' + feed_item.user.user_id" class="user">{{feed_item.user.data_full_name || feed_item.user.data_name}}</router-link>
                                            <div class="event" v-html="feed_item.message"></div>
                                            <div class="time">{{feed_item.created | feed_moment}}</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="event-feed-action">
                                    <router-link to="/activites" class="btn tiny">More Activites</router-link>
                                </div>

                            </div>
                            <div v-else>

                                <div class="no-data feed-no-data">
                                    <div>
                                        <ion-icon name="ios-stats"></ion-icon>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- on load -->
                        <div v-else>
                            <loader type="dark" height="380" size="large"/>
                        </div>

                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-4">
                    <div class="box relative stats-box feedback">
                        <h2>Feedback <span class="sub">by Category</span> </h2>
                        <div>
                            <div>
                                <div>
                                    <canvas id="chart-type" height="300"></canvas> 
                                </div> 
                                <span class="total">Total {{stats.general && stats.general.total_items ? stats.general.total_items : 0 | format_stats}} posts</span>
                            </div>
                        </div>
                    </div> 
                </div>

                <!-- Section -->
                <div class="col-xs-12">
                    <div class="box feedback-list stats-box">

                        <h2>High Impact <span class="sub">posts you should focus on next</span></h2>

                        <!-- result area -->
                        <div v-if="feedback_query && feedback_query.results">
                            <div v-if="feedback_results_1.length > 0">
                                
                                <feedback-item :results="feedback_results_1" :settings="{impact: true}" :preview="false"></feedback-item>

                            </div>
                            <div v-else>

                                <div class="no-data">
                                    <div>
                                        <ion-icon name="ios-stats"></ion-icon>
                                        <div class="msg">
                                            <span class="head">No results</span>
                                            <span class="txt">Have a little more patience, it may take some time for results to show up here.</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- on load -->
                        <div v-else>
                            <loader type="dark" height="200" size="large"/>
                        </div>

                    </div>
                </div>

                <!-- Section -->
                <div class="col-xs-12">
                    <div class="box feedback-list stats-box">

                        <h2>Upcoming</h2>

                        <!-- result area -->
                        <div v-if="feedback_query && feedback_query.results">
                            <div v-if="feedback_results_2.length > 0">
                                
                                <feedback-item :results="feedback_results_2" :settings="{impact: true}" :preview="false"></feedback-item>

                            </div>
                            <div v-else>

                                <div class="no-data">
                                    <div>
                                        <ion-icon name="ios-stats"></ion-icon>
                                        <div class="msg">
                                            <span class="head">No results</span>
                                            <span class="txt">Have a little more patience, it may take some time for results to show up here.</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- on load -->
                        <div v-else>
                            <loader type="dark" height="200" size="large"/>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="stats-updated" v-if="stats">
            Dashboard last updated {{stats.updated | time_ago}}
        </div>

        <!-- Load ambassador -->
        <ambassador></ambassador>

    </div>
</template>

<script>
import feedbackitem from '@/components/module.loc.feedback-item.vue';
import Chart from 'chart.js';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import message from '@/components/module.loc.message.vue';
import onboarder from '@/components/module.loc.onboarder.vue';

export default {


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            query: {type: 'all', sort: 'scoring', filter: 'open'},
            chart_satisfaction: false,
            chart_pageviews: false,
            chart_stats: false,
            load_pageviews: false,
            welcome_message: false,
            stats_range: 30,
            pageview_type: null,
        };

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product() {
            return this.$store.getters.product_active;
        },

        product_data() {
            return this.$store.getters.product_data;
        },

        product_analytics() {
            return this.$store.getters.product_analytics;
        },

        stats() {
            return ((this.$store.getters.stats_general && this.$store.getters.stats_general[this.stats_range]) ? this.$store.getters.stats_general[this.stats_range] : false);
        },

        stats_30d() {
            return ((this.$store.getters.stats_general && this.$store.getters.stats_general[30]) ? this.$store.getters.stats_general[30] : false);
        },

        feedback_items() {
            return this.$store.getters.feedback_items;
        },

        feedback_query() {
            return this.$store.getters.feedback_query[JSON.stringify(this.query)];
        },

        feedback_results_1() {
            var _1 = Object.assign({}, this.feedback_query);
            return ((_1.results.length > 0) ? _1.results.slice(0, 4) : {});
        },

        feedback_results_2() {
            var _2 = Object.assign({}, this.feedback_query);
            return ((_2.results.length > 0) ? _2.results.slice(4) : {});
        },

        product_feed() {
            return ((this.$store.getters.product_feed['all']) ? this.$store.getters.product_feed['all'].results : false);
        },
        
        user() {
            return this.$store.getters.user_data;
        },

        organization() {
            return ((!this.product.organization_id || parseInt(this.product.organization_id) == 0 || parseInt(this.product.organization_id) !== parseInt(this.product.ID)) ? false : true);
        },

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /** 
         * Method: Load pageviews chart
        */
        load_pageviews_chart() {

            // init chart element
            var links_pageviews = document.getElementById('analytics-pageviews').getContext('2d');
            var analytics_pageviews_gs = links_pageviews.createLinearGradient(800, 0, 100, 0);
                analytics_pageviews_gs.addColorStop(0, '#00b3a4');
                analytics_pageviews_gs.addColorStop(1, '#5aa7de');

            if( this.chart_pageviews == false ) {

                // draw a chart
                new Chart(links_pageviews, {
                    type: 'line',
                    data: {
                        labels: this.product_analytics[((this.pageview_type) ? 'pageviews_' + this.pageview_type : 'pageviews')].query.labels,
                        datasets: [{
                            borderColor: analytics_pageviews_gs,
                            pointBorderColor: '#fff',
                            pointBackgroundColor: analytics_pageviews_gs,
                            pointHoverBackgroundColor: analytics_pageviews_gs,
                            pointHoverBorderColor: analytics_pageviews_gs,
                            backgroundColor: '#ffffff',
                            fill: false,
                            pointRadius: ((this.product_analytics[((this.pageview_type) ? 'pageviews_' + this.pageview_type : 'pageviews')].query.data && this.product_analytics[((this.pageview_type) ? 'pageviews_' + this.pageview_type : 'pageviews')].query.data.length < 60) ? 5 : 0 ),
                            borderWidth: 3,
                            label: 'Average customer satisfaction',
                            data: this.product_analytics[((this.pageview_type) ? 'pageviews_' + this.pageview_type : 'pageviews')].query.data,
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: false,
                        layout: {
                            padding: {
                                top: 5,
                                bottom: 5
                            }
                        },
                        scales: {
                            yAxes: [{
                                display: true,
                                ticks: {
                                    stepSize: 1,
                                    suggestedMax: 5,
                                    suggestedMin: 0,
                                    autoSkip: true,
                                    maxTicksLimit: 7,
                                    maxRotation: 0,
                                    minRotation: 0,
                                    display: true,
                                    beginAtZero: true,
                                },
                                gridLines: {
                                    display: true,
                                    color: "#f5f8fa",
                                    drawBorder: false
                                }
                            }],
                            xAxes: [{
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        'millisecond': 'MMM DD',
                                        'second': 'MMM DD',
                                        'minute': 'MMM DD',
                                        'hour': 'MMM DD',
                                        'day': 'MMM DD',
                                        'week': 'MMM DD',
                                        'month': 'MMM DD',
                                        'quarter': 'MMM DD',
                                        'year': 'MMM DD',
                                    }
                                },
                                display: true,
                                ticks: {
                                    beginAtZero: true,
                                    autoSkip: true,
                                    maxTicksLimit: 7,
                                    maxRotation: 0,
                                    minRotation: 0
                                },
                                gridLines: {
                                    display:false,
                                    drawBorder: false
                                },
                            }]
                        }
                    }
                });

            } else {
                
                this.chart_pageviews.data.labels = this.stats.type.label;
                this.chart_pageviews.data.datasets.forEach((dataset) => {
                    dataset.data = this.stats.type.value;
                });
                this.chart_pageviews.update();
                
            }

        },

        /** 
         * Method: Load types pie chart
        */
        load_types_chart() {

            // make sure we dont run into anny issues here!
            try {
                // check if we have type stats
                if( this.stats && this.stats.type && this.stats.type.value ) {
                    // do bubble sort
                    // outer pass
                    for(let i = 0; i < this.stats.type.value.length; i++){
                        // inner pass
                        for(let j = 0; j < this.stats.type.value.length - i - 1; j++){
                            // check if current value is greater then next
                            if(this.stats.type.value[j + 1] > this.stats.type.value[j]){
                                // swap values & color & label
                                [this.stats.type.value[j + 1],this.stats.type.value[j]] = [this.stats.type.value[j],this.stats.type.value[j + 1]];
                                [this.stats.type.color[j + 1],this.stats.type.color[j]] = [this.stats.type.color[j],this.stats.type.color[j + 1]];
                                [this.stats.type.label[j + 1],this.stats.type.label[j]] = [this.stats.type.label[j],this.stats.type.label[j + 1]];
                            }
                        }
                    };
                }
            } catch (error) {}

            // make sure we dont run into anny issues here!
            try {
                // prepare label names
                var stats_label = [];
                // check if we have label names
                if( this.stats && this.stats.type.label && this.product_data && this.product_data.product_settings ) {
                    // load each label
                    this.stats.type.label.forEach( (item, index) => {
                        // check if we have data for that type
                        if( this.product_data.product_settings.type[item] ) {
                            // set name
                            stats_label[index] = this.product_data.product_settings.type[item].name;
                        } else {
                            stats_label[index] = item;
                        }
                    });
                }
            } catch (error) {}

            if( this.chart_stats == false ) {

                // load chart
                this.chart_stats = new Chart(document.getElementById('chart-type').getContext('2d'), {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: (this.stats) ? this.stats.type.value : [50, 50, 50],
                            backgroundColor: (this.stats) ? this.stats.type.color: ['#eee', '#cecece', '#e4e8ea'],
                            label: '',
                        }],
                        labels: (this.stats) ? stats_label : ['loading data...', 'loading data...', 'loading data...']
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 70, 
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                boxWidth: 10,
                                boxHeight: 10,
                                padding: 10,
                                // filter labels to max 5 items
                                filter: function(legendItem, data) {

                                    // dont modify for "loading data..."
                                    if( legendItem.text == 'loading data...' ) {
                                        return true;
                                    }

                                    // set label data
                                    const ln   = 10;
                                    const labelIndex    = legendItem.index; // I'm using lodash here
                                    const qtd           = data.datasets[0].data[labelIndex];
                                    const txt           = ( (legendItem.text.length > ln) ? legendItem.text.slice(0, ln-1) + '...' : legendItem.text );

                                    // Second, mutate the legendItem to include the new text
                                    legendItem.text = `${txt} (${qtd})`;

                                    return ((legendItem.index < 5 && qtd > 0) ? true : false);
                                }
                            }
                        },
                        title: {
                            display: false,
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        }
                    }
                });

            } else {
                
                this.chart_stats.data.labels = stats_label;
                this.chart_stats.data.datasets.forEach((dataset) => {
                    dataset.data = this.stats.type.value;
                    dataset.backgroundColor = this.stats.type.color;
                });
                this.chart_stats.update();

            }

        },

        /**
         *  Load query
         */
        load_welcome_message() {

            // load welcome message
            var welcome_message = '';
            var welcome_name    = '';

            // message variations
            var data = [
                [0, 4,      "Good night"], 
                [5, 11,     "Good morning"],
                [12, 17,    "Good afternoon"],
                [18, 24,    "Good evening"]
            ],
            hr = new Date().getHours();

            // load message based on user time
            for(var i = 0; i < data.length; i++){
                if(hr >= data[i][0] && hr <= data[i][1]){
                    welcome_message = data[i][2];
                }
            }

            // set full name
            var full_name = this.user.user_name;
            // set default results object
            var result = {
                name:               '',
                last_name:          '',
                second_last_name:   '',
            };

            // parse name
            if (full_name.length > 0) {

                // name tokens
                var name_tokens = full_name.match(/[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g) || [];

                // check if Regex found results
                if( name_tokens.length !== 0 ) {

                    // first name
                    if (name_tokens.length > 3) {
                        result.name = name_tokens.slice(0, 2).join(' ');
                    } else {
                        result.name = name_tokens.slice(0, 1).join(' ');
                    }

                    // last name
                    if (name_tokens.length > 2) {
                        result.last_name = name_tokens.slice(-2, -1).join(' ');
                        result.second_last_name = name_tokens.slice(-1).join(' ');
                    } else {
                        result.last_name = name_tokens.slice(-1).join(' ');
                        result.second_last_name = "";
                    }

                } else {
                    // set basic name
                    result.name = full_name.split(' ').slice(0, 1).join(' ');
                }

            }

            // set welcome name
            welcome_name = result.name;

            // set welcome message
            this.welcome_message = welcome_message + ', ' + welcome_name;

            // done
            return true;

        },

        /**
         *  Load query
         */
		feedback_load( current_page = 0 ) {

            // load feedback query
			this.$store.dispatch( 'feedback_query', {query: this.query, page: current_page } );
			
        },
        
        /**
         *  Load pageviews
         */
		pageviews_load( force = false ) {

            // check if we have data
            if( force == false && this.product_analytics !== false && typeof this.product_analytics[((this.pageview_type) ? 'pageviews_' + this.pageview_type : 'pageviews')] !== "undefined" ) {
                // load chart
                this.load_pageviews_chart();
                // stop here
                return true;
            }

            // set loading state
            this.load_pageviews = true;

            // load satisfaction query
			this.$store.dispatch( 'product_analytics_pageviews_load', {start: moment().subtract(this.stats_range, 'days').toDate(), end: moment().toDate(), key: this.pageview_type} ).then(() => { 
                // load chart
                this.load_pageviews_chart();
                // unset loading state
                this.load_pageviews = false;
            }).catch(e => {});
			
        },

        /**
         *  Load product feed
         */
		product_feed_load() {

            // load feedback query
            this.$store.dispatch( 'product_feed_get', {page: 0, filter: 'all'} );
			
        },

        /**
         *  Load product stats
         */
		product_stats_load() {

            // load feedback query
            this.$store.dispatch( 'stats_general_load', this.stats_range ).then(() => { 
                // load pie
                this.load_types_chart();
            }).catch(e => {});

        },

        /**
         *  Set product stats range
         */
		product_stats_range( range ) {

            // set range
            this.stats_range = range;

            // load product stats
            this.product_stats_load();

            // load pageviews
            this.pageviews_load(true);

        },

        /**
         * 
         */
        product_pageviews_type( type ) {

            // set type
            this.pageview_type = type;

            // load pageviews
            this.pageviews_load(true);

        }
        
    },


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        'feedback-item': feedbackitem,
        DatePicker,
        'message': message,
        'onboarder': onboarder,
        'organization-home': () => import('@/views/organization-home.vue'),
        'ambassador': () => import('@/components/module.loc.ambassador.vue'),
    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted () {

        // load welcome message
        this.load_welcome_message();

        // load pie
        this.load_types_chart();

        // load pageviews
        this.pageviews_load();

        // check if we have data
        if( ! this.stats ) {
            // load product stats
            this.product_stats_load();
        }

        // check if we have data
        if( ! this.feedback_query ) {
            // load feedback
            this.feedback_load();
        }

        // check if we have feed data
        if( ! this.product_feed ) {
            // load product feed
            this.product_feed_load();
        }

    },


    /** ----------------------------------------------------
     * Filter
     ----------------------------------------------------- */
    filters: {
        moment: function (date) {
            return moment(date).utc().format('MMMM MM, YYYY');
        },
        format_stats: function (number) {
            return ((number < 10000) ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : Math.sign(number)*((Math.abs(number)/1000).toFixed(1)) + 'k' );
        },
        time_ago: function (date) {
            return moment.utc(date).fromNow();
        },
        feed_moment: function (date) {
            return moment.utc(date).fromNow();
        }
    },

}
</script>